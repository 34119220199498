import {Button, Form, Modal, Tooltip} from 'antd';
import {useForm} from 'antd/es/form/Form';
import moment from 'moment';
import {Lock, LockOpen, Swap, Wallet} from 'phosphor-react';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {useLocation} from 'react-router-dom';
import {rotateSvg} from '../../../../../assets/icons/compIcons/rotateSvg';
import {validateMessages} from '../../../../../Components/AuthModalForm';
import FormGroup from '../../../../../Components/formItems/FormGroup';
import Toast from '../../../../../Components/Toast';
import {TransportCardBack, TransportCardFront} from '../../../../../Components/TransportCard/TransportCard';
import {topUpHoldersBalance, topUpHolderWithBalance} from '../../../../../store/holdersActions.slice';
import {changHolderStatusThunk, getHoldersThunk} from '../../../../../store/myHolders.slice';
import {getBalanceString} from '../../../../../utils/heplerFuncs/functions';
import {
	numberOnlyRule,
	numberRulesAmount,
	numberRulesAmountLittle
} from '../../../../../utils/rules/validation';
import i18n from '../../../../../utils/translate/i18';
import {ModalFormFieldTypesFunc} from '../../../../../utils/types';
import fastShift from './../../../../../assets/fast-shift-logo.png';
import masterCard from './../../../../../assets/icons/mastercard.svg';
import visa from './../../../../../assets/icons/visa.svg';
import frame from './../../../../../assets/icons/Wallet.png';

interface TicketHolderItemProps {
	options: any;
	getbalance: string;
	ticketId: any;
	setForceUpdate?: any;
	forceUpdate?: boolean;
}

const TicketHolderItem = ({options, ticketId, setForceUpdate, forceUpdate}: TicketHolderItemProps) => {
	const location = useLocation();
	const {t} = useTranslation();
	const [modal, setModal] = useState([false || location.hash === '#showBalance', false]);
	const [blockPopUp, setBlockPopUp] = useState(false);
	const dispatch = useDispatch<any>();
	const [form] = useForm();
	const [topUpForm] = useForm();
	const formErrors = useSelector((state: any) => state.ticketHolders.formErrors);
	const [flip, setFlip] = useState(false);
	const isMobile = useMediaQuery({maxWidth: '768px'});
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [formErrorsTopUp, setFormErrorsTopUp] = useState<string[]>([]);
	const auth = useSelector(({auth}) => auth.data);

	const callPopup = useCallback((type: string, open: boolean) => {
		switch (type) {
			case 'topUpBalance':
				setModal(p => {
					p[2] = open;
					return [...p];
				});
				topUpForm.resetFields();
				break;
		}
	}, []);

	const onSubmitTopUp = (type: 'visa' | 'fastshift' | 'balance') => {
		topUpForm
			.validateFields()
			.then(res => {
				const amount = topUpForm.getFieldValue('amount');
				const holderNumber = options.number;
				switch (type) {
					case 'visa':
						setPaymentLoading(true);
						let payload = {
							providerId: 3,
							amount: String(amount),
							holderNumber: holderNumber.replace(/[^\d]/g, '')
						};
						dispatch(topUpHoldersBalance(payload))
							.unwrap()
							.then((response: any) => {
								if (response.data.url) {
									window.location.href = response.data.url;
								}
							})
							.catch((response: any) => {
								Toast({
									description: response.code
										? t(`messages.code.${response.code}`)
										: t(`messages.code.0`),
									type: 'error',
									duration: 2.5
								});
							})
							.finally(() => {
								setPaymentLoading(false);
							});
						break;
					case 'balance': {
						dispatch(topUpHolderWithBalance({amount: String(amount), holderId: options.id}))
							.unwrap()
							.then((res: any) => {
								Toast({
									description: t(`messages.code.${res.code}`),
									type: 'success',
									duration: 2.5
								});
								setForceUpdate(!forceUpdate);
								callPopup('topUpBalance', false);
							})
							.catch((response: any) => {
								Toast({
									description: response.code
										? t(`messages.code.${response.code}`)
										: t(`messages.code.0`),
									type: 'error',
									duration: 2.5
								});
							});
						break;
					}

					case 'fastshift':
						// dispatch({providerId:2})
						break;
				}
			})
			.catch(err => {
				const error = err.errorFields?.[0]?.name?.[0];
				if (error) {
					const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
					errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
				}
			});
	};
	const handleChangesStatus = () => {
		const newStatusId = options.statusId === 1010 || options.statusId === 1030 ? 1020 : 1030;
		dispatch(changHolderStatusThunk({statusId: newStatusId, ticketId}))
			.unwrap()
			.then(() => {
				setBlockPopUp(false);
				dispatch(getHoldersThunk({}));
				Toast({type: 'success', description: t('messages.success.success')});
			})
			.catch(() => {
				Toast({type: 'error', description: t('messages.error.badRequest')});
			});
	};

	const createModal = ({
		modalIndex,
		titleKey,
		className,
		content,
		onOk,
		onCancel,
		disableFooter
	}: {
		modalIndex: number;
		titleKey: string;
		className: string;
		content: React.ReactNode;
		onOk?: () => void;
		onCancel?: () => void;
		disableFooter?: boolean;
	}) => (
		<Modal
			key={modalIndex}
			destroyOnClose
			centered
			footer={disableFooter && null}
			closeIcon={null}
			className={`ticket-holder__popup ${className}`}
			title={t(`pages.account.ticketHoldersPage.actions.${titleKey}.popup.title`)}
			open={modal[modalIndex]}
			okText={t(`pages.account.ticketHoldersPage.actions.${titleKey}.popup.okText`)}
			onOk={onOk ?? (() => callPopup(titleKey, false))}
			onCancel={onCancel ?? (() => callPopup(titleKey, false))}>
			{content}
		</Modal>
	);

	const TopUpModal = () => {
		return (
			<>
				<Form
					form={topUpForm}
					layout="vertical"
					validateTrigger="onBlur"
					validateMessages={validateMessages(t)}
					onValuesChange={() => {}}>
					<FormGroup tabFields={topUpFields(t)} formErrors={formErrorsTopUp} form={topUpForm} />
				</Form>
				<p>{t('pages.tickets.oneUse.popup.selectPaymentMethod')}</p>
				<div className="my-balance__payment-methods">
					{+auth.balance > 0 && (
						<div className="my-balance__payment-method first-method">
							<div className="payment-method-imgs">
								<img src={frame} alt="" />
								<span
									style={{
										fontSize: 18,
										color: '#009e84cd',
										marginLeft: 5,
										fontWeight: 600
									}}>
									{t(
										'pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byBalance'
									)}
								</span>
							</div>
							<Button
								onClick={() => {
									onSubmitTopUp('balance');
								}}>
								{t('pages.account.myBalancePage.top.payOnline.paymentMethod.balance.title')}
							</Button>
						</div>
					)}

					<div className="my-balance__payment-method first-method">
						<div className="payment-method-imgs">
							<img src={visa} alt="" />
							<img src={masterCard} alt="" />
						</div>
						<Button
							disabled={paymentLoading}
							onClick={() => {
								onSubmitTopUp('visa');
							}}>
							{t('pages.account.myBalancePage.top.payOnline.paymentMethod.visa.title')}
						</Button>
					</div>
					<div className="my-balance__payment-method">
						<div className="payment-imgs">
							<img src={fastShift} alt="" style={{marginBottom: isMobile ? 10 : 0}} />
						</div>

						<Button
							disabled={paymentLoading}
							onClick={() => {
								onSubmitTopUp('fastshift');
							}}>
							{t('pages.account.myBalancePage.top.payOnline.paymentMethod.fastShift.title')}
						</Button>
					</div>
				</div>
			</>
		);
	};
	const topUpFields: ModalFormFieldTypesFunc = t => [
		{
			name: 'amount',
			label: t('pages.account.partner.transfersHistory.totalamount1'),
			type: 'text',
			rules: [numberOnlyRule(t), numberRulesAmountLittle(t, 10), numberRulesAmount(t, 100000)],
			style: {
				height: 68
			},
			colProps: {
				lg: 24,
				xs: 24
			}
		}
	];
	const modals = [
		createModal({
			modalIndex: 2,
			titleKey: 'topUpBalance',
			className: 'topUpBalance',
			content: <TopUpModal />,
			disableFooter: true
		})
	];

	const getCardStyle = (statusId: number) => {
		switch (statusId) {
			case 1010:
				return '';
			case 1020:
				return 'notVerificated';
			case 1030:
				return '';
			case 1040:
				return 'notVerificated';
			default:
				return '';
		}
	};
	const cardStyle = getCardStyle(options.statusId);
	const moreInfoItems = [
		{
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'),
			value:
				options.statusId === 1040 || options.statusId === 1020
					? t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.blocked')
					: t('pages.account.ticketHoldersPage.filter.status.options.activated')
		},
		{
			title: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byBalance'),
			value: getBalanceString(t, options?.balance, options?.tariff?.typeId)
		},
		{
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.registrationDate'),
			value: moment(options.createdAt).format('DD-MM-YYYY')
		},
		{
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.validDate.title'),
			value: moment(options.validDate).format('DD-MM-YYYY')
		},
		{
			title: t('pages.account.historyMarches.product'),
			value: options?.tariff ? options?.tariff[i18n.language] : '-'
		}
	];

	const holderActions = [
		{
			hidden: false,
			component: (
				<div className="ticket-holder-card__reverse" style={{flex: 'unset'}}>
					<Tooltip title={t('pages.account.ticketHoldersPage.ticketHolder.rotateCard')}>
						<div className="card-reverse__main">
							<div
								className={`card-reverse__ico ${flip && 'reversed'}`}
								onClick={() => setFlip(!flip)}>
								{rotateSvg}
							</div>
						</div>
					</Tooltip>
				</div>
			)
		},
		{
			hidden: false,
			component: (
				<div className="action-button-container" onClick={() => setBlockPopUp(true)}>
					<Tooltip
						title={
							options.statusId === 1010 || options.statusId === 1030
								? t('pages.account.ticketHoldersPage.actions.blockBalance.btnTitle1')
								: t('pages.account.ticketHoldersPage.actions.blockBalance.btnTitle')
						}>
						{options.statusId === 1010 || options.statusId === 1030 ? (
							<Lock size={35} />
						) : (
							<LockOpen size={35} />
						)}
					</Tooltip>
				</div>
			)
		},

		{
			hidden: options?.tariff?.typeId !== 3 || options.tariffId === null,
			component: (
				<div className="action-button-container" onClick={() => callPopup('topUpBalance', true)}>
					<Tooltip title={t('pages.account.ticketHoldersPage.actions.topUpBalance.title')}>
						<Wallet size={35} />
					</Tooltip>
				</div>
			)
		},
		{
			hidden: false,
			component: (
				<div className="action-button-container">
					<Tooltip title={t('pages.account.ticketHoldersPage.actions.changeTariff.title')}>
						<Swap size={35} />
					</Tooltip>
				</div>
			)
		}
	];

	return (
		<div className="ticket-holder__item">
			<div className="ticket-holder__item-columns">
				{/* header and ticket card */}
				<div className="ticket-holder__item-column">
					<div className="ticket-holder__item-top">
						<div className="ticket-holder__item-header">
							<p>{t('pages.tickets.oneUse.ticketCard.storageType')}</p>
						</div>
					</div>
					<div className="ticket-holder-card__container">
						<div className={`ticket-holder-card__flip flip-box ${flip ? 'true' : ''}`}>
							<div className="ticket-holder-card__main flip-box-inner">
								<div className={`ticket-card__frontside flip-box-front ${cardStyle}`}>
									<TransportCardFront />
								</div>
								<div className={`ticket-card__backside flip-box-back ${cardStyle}`}>
									<TransportCardBack number={options.number} />
								</div>
							</div>
						</div>
						<div className="ticket-holder__item-column description">
							<div className="ticket-holder__item-description">
								{/* <div className="ticket__item-description">
									<p>
										{t('pages.account.ticketHoldersPage.ticketHolder.description.title')}
									</p>
								</div> */}
								<div className="holder-item__more-info">
									<div className="more-info__items">
										{moreInfoItems.map((elem: any, index: any) => {
											return (
												<div className="more-info__item" key={index}>
													<div className="more-info__title">{elem.title}</div>
													<div
														className="more-info__data"
														style={{display: 'flex', gap: 20}}>
														<div className="more-info__data-col">
															<p>{elem?.value}</p>
														</div>
													</div>
												</div>
											);
										})}
									</div>
									<div
										className="action-btns"
										style={{
											display: 'flex',
											gap: 20,
											justifyContent: 'flex-end',
											marginTop: 20
										}}>
										{holderActions
											.filter(elem => !elem.hidden)
											.map(elem => elem.component)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* description and other info */}

				<Modal
					destroyOnClose
					centered
					closeIcon={null}
					className={`ticket-holder__popup block-balance`}
					title={t(`pages.account.ticketHoldersPage.actions.blockBalance.popup.title`)}
					open={blockPopUp}
					okText={t(
						`pages.account.ticketHoldersPage.actions.blockBalance.popup.${
							options.statusId === 1010 || options.statusId === 1030 ? 'okText' : 'okText1'
						}`
					)}
					onOk={() => handleChangesStatus()}
					onCancel={() => setBlockPopUp(false)}>
					{t(
						`pages.account.ticketHoldersPage.actions.blockBalance.popup.${
							options.statusId === 1010 || options.statusId === 1030 ? 'subTitle' : 'subTitle1'
						}`
					)}
				</Modal>
				{modals}
			</div>
		</div>
	);
};

export default TicketHolderItem;
