import {MenuOutlined} from '@ant-design/icons';
import {Badge, Button, Divider, Drawer, Dropdown, Layout, Menu, MenuProps} from 'antd';
import {Bell} from 'phosphor-react';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {Link, RouteProps, useLocation, useNavigate} from 'react-router-dom';
import enIC from '../../../assets/icons/en.png';
import hyIC from '../../../assets/icons/hy.png';
import logo from '../../../assets/icons/mainLogo (2).svg';
import {routes} from '../../../router';
import {getUserThunk, setAuthModalVisible, signOutThunk} from '../../../store/auth.slice';
import {setRoutes} from '../../../store/routes.slice';
import {fetchImage} from '../../../utils/fetchImage/fetchImage';
import {getKeyByLang} from '../../../utils/heplerFuncs/functions';
import i18n from '../../../utils/translate/i18';
import {IRouteProps} from '../../../utils/types';
import AuthModalForm from '../../AuthModalForm';
import Toast from '../../Toast';
import './Header.less';

const {Header, Content} = Layout;

const LANGUAGES = {
	hy: hyIC,
	en: enIC
};

interface IMenuProps {
	label: string;
	key: string;
	icon: React.ReactNode;
}

const items: IMenuProps[] = [
	{
		label: 'ՀԱՅ',
		key: 'hy',
		icon: <img src={hyIC} alt="" width="20px" />
	},
	{
		label: 'ENG',
		key: 'en',
		icon: <img src={enIC} alt="" width="20px" />
	}
];

const AppHeader = () => {
	const {t} = useTranslation();
	const pages = useSelector(({routes}) => routes.data);
	const auth = useSelector(({auth}) => auth);
	const authModalVisible = auth.modal;
	const user = auth.data;
	const account = pages.find((el: RouteProps) => el.path === (user.roleId === 4 ? '/account' : '/partner'));
	const location = useLocation();
	const choosenPage = location.pathname;
	const isMobile = useMediaQuery({maxWidth: '1200px'});
	const [menu, setMenu] = useState(false);
	const [drawer, setDrawer] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleMenuClick: MenuProps['onClick'] = e => {
		if (e.key === i18n.language) return;
		i18n.changeLanguage(e.key);
		localStorage.setItem('language', e.key);
		dispatch(setRoutes(routes(t)));
	};

	const loggedMenuProps = [
		{
			key: account.path,
			label: account.label,
			path: account.path,
			onClick: () => {
				navigate(account.path);
			},
			disabled: auth.loading
		},
		...account.subMenu.map((el: any) => ({
			key: el.path,
			path: el.path,
			label: el.label,
			onClick: () => {
				navigate(el.path);
			},
			disabled: auth.loading
		})),
		{
			key: 'logOut',
			label: t('header.sign_out'),
			path: '',
			onClick: () => {
				dispatch(signOutThunk())
					.unwrap()
					.then(() => {
						localStorage.clear();
						// clearLocalStorage();
						// dispatch(logOutUser());
						dispatch(getUserThunk());
					})
					.catch((err: any) => {
						Toast({
							description: err.code ? t(`messages.code.${err.code}`) : t(`messages.code.0`),
							type: 'success',
							duration: 2.5
						});
					});
			},
			disabled: auth.loading
		}
	];

	const langProps = {
		items: items.filter((el: IMenuProps) => el.key !== i18n.language),
		onClick: handleMenuClick
	};

	const menuItems = useMemo(() => {
		return pages
			.filter((el: IRouteProps) => el.navBar)
			.map((el: IRouteProps) => ({
				label: el.label,
				key: el.path,
				disabled: el.disabled,
				path: el.path,
				children:
					el.subMenu &&
					el.subMenu.map((subel: any) => ({
						...subel,
						onClick: () => {
							choosenPage !== subel.path && navigate(subel.path);
						},
						key: subel.path,
						path: subel.path
					})),
				onClick: () => {
					choosenPage !== el.path && !el.subMenu && el.path && navigate(el.path);
				}
			}));
	}, [pages, navigate, choosenPage]);

	useEffect(() => {
		setMenu(false);
	}, [isMobile]);
	const [imageSrc, setImageSrc] = useState('');

	useEffect(() => {
		fetchImage(auth.data.avatar, setImageSrc);

		return () => {
			URL.revokeObjectURL(imageSrc);
		};
	}, [auth.data.avatar]);
	return (
		<>
			<Header className="header">
				<Content className="content_header">
					<Link to={'/'} className="logo_link">
						<img
							src={logo}
							alt=""
							className="logo_link__logo"
							onClick={() => {
								window.scrollTo({top: 0, behavior: 'smooth'});
							}}
						/>
					</Link>

					{!isMobile && (
						<div className="right-actions">
							<Menu
								onClick={() => {
									window.scrollTo({top: 0, behavior: 'smooth'});
								}}
								className="menu"
								items={menuItems}
								mode="horizontal"
								disabledOverflow
								defaultSelectedKeys={[choosenPage]}
								selectedKeys={[choosenPage]}
							/>
							<Dropdown trigger={['click']} placement="bottom" menu={langProps}>
								<span className="lang-link">
									<img
										src={LANGUAGES[i18n.language as 'hy' | 'en']}
										width="20px"
										alt={i18n.language}
									/>
								</span>
							</Dropdown>

							{user.isAuthenticated ? (
								<>
									{user.roleId === 4 && (
										<Badge dot style={{margin: '10px 8px', padding: '5px'}}>
											<Link
												to={'/account'}
												onClick={() =>
													setTimeout(
														() =>
															window.scrollTo({
																top: document.body.scrollHeight / 4,
																behavior: 'smooth'
															}),
														500
													)
												}>
												<Button
													type="text"
													className="bell-header"
													style={{marginTop: 3}}>
													<Bell size={26} />
												</Button>
											</Link>
										</Badge>
									)}
									<Menu
										className="menu-logged"
										items={loggedMenuProps}
										mode="horizontal"
										rootClassName="menu-login"
										overflowedIndicator={
											<div className="loged_in">
												<MenuOutlined />
												{user.avatar ? (
													<img
														src={imageSrc}
														className="ic"
														alt="avatar"
														style={{objectFit: 'cover'}}
													/>
												) : (
													<div className="name">
														{user[getKeyByLang('companyName')] && user.firstName
															? `${user[getKeyByLang('companyName')][0]}`
															: user.firstName
															? `${user.firstName[0]}${user.lastName[0]}`
															: ''}
													</div>
												)}
											</div>
										}
										defaultSelectedKeys={[choosenPage]}
										selectedKeys={[choosenPage]}
									/>
								</>
							) : (
								<div className="sign">
									<span
										onClick={() => {
											dispatch(setAuthModalVisible({visible: 'SIGN_IN'}));
										}}>
										{t('header.sign_in')}
									</span>
									/
									<span
										onClick={() => {
											dispatch(setAuthModalVisible({visible: 'SIGN_UP'}));
										}}>
										{t('header.sign_up')}
									</span>
								</div>
							)}
						</div>
					)}

					{isMobile && (
						<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
							<div className="header-lang">
								{/* <Dropdown trigger={['click']} placement="bottom" menu={langProps}> */}
								<span
									className="lang-link"
									onClick={() => i18n.changeLanguage(i18n.language === 'hy' ? 'en' : 'hy')}>
									<img
										src={LANGUAGES[i18n.language as 'hy' | 'en']}
										width="20px"
										alt={i18n.language}
									/>
								</span>
								{/* </Dropdown> */}
							</div>
							{user.isAuthenticated && user.roleId === 4 && (
								<Badge dot style={{margin: '6px', padding: '5px'}}>
									<Link
										to="/account#menu"
										onClick={() =>
											setTimeout(
												() =>
													window.scrollTo({
														top: document.body.scrollHeight / 4,
														behavior: 'smooth'
													}),
												500
											)
										}>
										<Button type="text" className="bell-header">
											<Bell size={26} />
										</Button>
									</Link>
								</Badge>
							)}

							<div
								className={`loged_in ${!user.isAuthenticated && 'padding'}`}
								onClick={() => {
									setDrawer(true);
									setMenu(true);
								}}>
								<MenuOutlined />

								{user.isAuthenticated &&
									(user.avatar ? (
										<img
											src={imageSrc}
											className="ic"
											alt="avatar"
											style={{objectFit: 'cover'}}
										/>
									) : (
										<div className="name">
											{user[getKeyByLang('companyName')] && user.firstName
												? `${user[getKeyByLang('companyName')][0]}`
												: user.firstName
												? `${user.firstName[0]}${user.lastName[0]}`
												: ''}
										</div>
									))}
							</div>
						</div>
					)}
				</Content>
			</Header>

			{isMobile && drawer && (
				<Drawer
					className="drawer"
					placement="right"
					onClose={() => setMenu(false)}
					open={menu}
					width={250}
					key="right">
					<Menu
						onClick={() => setMenu(false)}
						className="menu"
						items={menuItems}
						mode="inline"
						disabledOverflow
						openKeys={['/news/:city', '/marches']}
						defaultSelectedKeys={[choosenPage]}
						selectedKeys={[choosenPage]}
					/>

					{user.isAuthenticated ? (
						<div className="sign">
							{loggedMenuProps
								.filter(elem => {
									return elem.key !== 'logOut';
								})
								.map((elem: any, index: number) => {
									return (
										<div
											key={index}
											onClick={() => {
												setMenu(false);
												elem.onClick();
											}}>
											<span>{elem.label}</span>
											<Divider />
										</div>
									);
								})}
							{/* <Dropdown trigger={['click']} placement="bottom" menu={langProps}>
								<div className="lang-link">
									<div className="lang-ico">
										<img
											src={LANGUAGES[i18n.language as 'hy' | 'en']}
											width="20px"
											alt={i18n.language}
										/>
									</div>

									<p>{items.find((el: IMenuProps) => el.key === i18n.language)?.label}</p>
								</div>
							</Dropdown> */}
							{loggedMenuProps
								.filter(elem => {
									return elem.key === 'logOut';
								})
								.map((elem: any, index: number) => {
									return (
										<div
											key={index}
											onClick={() => {
												setMenu(false);
												elem.onClick();
											}}>
											<span style={{fontWeight: 700}}>{elem.label}</span>
										</div>
									);
								})}
						</div>
					) : (
						<>
							<div className="sign">
								<span
									onClick={() => {
										setDrawer(false);
										dispatch(setAuthModalVisible({visible: 'SIGN_IN'}));
									}}>
									{t('header.sign_in')}
								</span>
								<Divider />
								<span
									onClick={() => {
										setDrawer(false);
										dispatch(setAuthModalVisible({visible: 'SIGN_UP'}));
									}}>
									{t('header.sign_up')}
								</span>
							</div>
							<Dropdown trigger={['click']} placement="bottom" menu={langProps}>
								<div className="lang-link">
									<div className="lang-ico">
										<img
											src={LANGUAGES[i18n.language as 'hy' | 'en']}
											width="20px"
											alt={i18n.language}
										/>
									</div>

									<p>{items.find((el: IMenuProps) => el.key === i18n.language)?.label}</p>
								</div>
							</Dropdown>
						</>
					)}
				</Drawer>
			)}
			<AuthModalForm {...authModalVisible} />
		</>
	);
};

export default AppHeader;
