import {Button, Form, Modal, Statistic} from 'antd';
import {NamePath} from 'antd/lib/form/interface';
import _ from 'lodash';
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {AppDispatch} from '../../store';
import {
	getUserThunk,
	resendOtpThunk,
	resetPasswordThunk,
	setAuthModalVisible,
	setFormErrors,
	setPasswordThunk,
	signInThunk,
	signInWithEmailThunk,
	signUpThunk
} from '../../store/auth.slice';
import {confirmEmailWithOtp, getauthOtp, signInWithEmailOtpThunk} from '../../store/authOtp.slice';
import {getforgotPassword} from '../../store/forgotPassword.slice';
import {getAuthFields, getDialogSize, getOkText, getTitle} from '../../utils/heplerFuncs/functions';
import {TranslationFuncType} from '../../utils/types';
import FormGroup from '../formItems/FormGroup';
import Toast from '../Toast';
import './styles.less';

export const validateMessages = (t: TranslationFuncType) => ({
	required: t('messages.error.requiredInputError'),
	types: {
		email: t('messages.error.emailInputError')
	}
});

interface ModalFormProps {
	visible:
		| false
		| 'SIGN_IN'
		| 'SIGN_UP'
		| 'FOGOT_PASSWORD'
		| 'OTP_VERIFICATION'
		| 'OTP_CONFIRMATION'
		| 'RESET_PASSWORD'
		| 'SIGN_IN_WITH_EMAIL'
		| 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL'
		| 'SET_PASSWORD';
	cancelText?: string | 'noCancel';
	formInitialValues?: any;
	onFormValuesChange?: (
		resetFields: (fields?: NamePath[]) => void,
		changedValues: any,
		allValues: any,
		setFieldsValue: (values: any) => void
	) => void;
}

const AuthModalForm: FC<ModalFormProps> = ({visible, cancelText, formInitialValues, onFormValuesChange}) => {
	const [form] = Form.useForm();
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [emailForOtp, setEmailForOtp] = useState<string | null>(null);
	const dialogSize = getDialogSize(visible);
	const dispatch = useDispatch<AppDispatch>();
	const auth = useSelector(({auth}) => auth);
	const isMobile = useMediaQuery({maxWidth: 480});
	const [captchaIsOk, setCaptchaIsOk] = useState(false);
	const {t} = useTranslation();
	const [isDisabled, setIsDisabled] = useState(true);
	const defaultDeadline = 60; //1 minute

	const [deadline, setDeadline] = useState(() => Date.now() + 1000 * defaultDeadline);
	const onChange = (value: any) => {
		setCaptchaIsOk(true);
	};
	const handleModalClose = () => {
		setCaptchaIsOk(false);
		setIsDisabled(true);
		setDeadline(0);
		dispatch(setAuthModalVisible({visble: false}));
		setIsSuccess(false);
		dispatch(setFormErrors({}));

		form.resetFields();
	};

	const onValuesChange = (changedValues: any, allValues: any) => {
		if (!_.isEmpty(auth.formErrors)) {
			dispatch(setFormErrors({}));
		}
		onFormValuesChange &&
			onFormValuesChange(form.resetFields, changedValues, allValues, form.setFieldsValue);
	};
	const onSubmit = () => {
		form.validateFields()
			.then(values => {
				const newValues = _.cloneDeep(values);
				for (const key in newValues) {
					if (
						Object.prototype.hasOwnProperty.call(newValues, key) &&
						typeof newValues[key] === 'string'
					) {
						newValues[key] = newValues[key].trim(); // Trim string values
					}
					if (
						Object.prototype.hasOwnProperty.call(newValues, key) &&
						newValues[key] === undefined
					) {
						newValues[key] = null; // Set undefined values to null
					}
				}
				if (visible === 'SIGN_IN') {
					dispatch(signInThunk(newValues))
						.unwrap()
						.then((res: any) => {
							localStorage.getItem('token') && dispatch(getUserThunk());
						})
						.catch((err: any) => {
							if (err.code === 33) {
								setEmailForOtp(newValues.email);
								dispatch(setAuthModalVisible({visible: 'OTP_CONFIRMATION'}));
							}
						});
				}
				if (visible === 'SIGN_UP') {
					let payload = {
						...newValues,
						password: newValues['new-password']
					};
					delete payload['new-password'];
					form.submit();
					form.validateFields()
						.then(() => {
							captchaIsOk
								? dispatch(signUpThunk(payload))
										.unwrap()
										.then(() => {
											setEmailForOtp(newValues.email);
											dispatch(setAuthModalVisible({visible: 'OTP_CONFIRMATION'}));
										})
								: Toast({
										description: t('pages.contact.reports.errorFields.reCaptcha'),
										type: 'error',
										duration: 2.5
								  });
						})
						.catch(err => {
							const error = err.errorFields?.[0]?.name?.[0];
							if (error) {
								const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
								errorField.scrollIntoView({
									behavior: 'smooth',
									block: 'center',
									inline: 'center'
								});
							}
						});
				}
				if (visible === 'SIGN_IN_WITH_EMAIL') {
					dispatch(signInWithEmailThunk({email: newValues.email}))
						.unwrap()
						.then(() => {
							setEmailForOtp(newValues.email);
							dispatch(setAuthModalVisible({visible: 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL'}));
							setIsSuccess(true);
						})
						.catch((err: any) => {
							console.log('asd');
							Toast({
								description: t(`messages.code.${err.code}`),
								type: 'error',
								duration: 2.5
							});
						});
				}
				if (visible === 'FOGOT_PASSWORD') {
					dispatch(getforgotPassword({email: newValues.email}))
						.unwrap()
						.then(() => {
							setEmailForOtp(newValues.email);
							dispatch(setAuthModalVisible({visible: 'OTP_VERIFICATION'}));
							setIsSuccess(true);
						})
						.catch((err: any) => {
							Toast({
								description: t(`messages.code.${err.code}`),
								type: 'error',
								duration: 2.5
							});
						});
				}
				if (visible === 'OTP_VERIFICATION') {
					// WHEN FORGOT PASSWORD OR OTHER CASE EXCEPT SIGNING IN
					dispatch(getauthOtp({email: emailForOtp, otp: newValues.otp}))
						.unwrap()
						.then((res: any) => {
							localStorage.setItem('token', JSON.stringify(res.data.token).replace(/"/g, ''));
							Toast({
								description: t(`messages.code.${res.code}`),
								type: 'success',
								duration: 2.5
							});

							dispatch(setAuthModalVisible({visible: 'RESET_PASSWORD'}));
						})
						.catch((err: any) => {
							const seconds =
								err.statusCode === 429 && err.code === 35 && err?.message?.match(/(\d+)/)[0];
							Toast({
								description:
									err.statusCode === 429 && err.code === 35
										? t(`messages.code.${err.code}`, {seconds})
										: t(`messages.code.${err.code}`),
								type: 'error',
								duration: 2.5
							});
						});
				}
				if (visible === 'OTP_CONFIRMATION') {
					// WHEN SIGNING UP ONLY
					dispatch(confirmEmailWithOtp({email: emailForOtp, otp: newValues.otp}))
						.unwrap()
						.then((res: any) => {
							Toast({
								description: t(`messages.code.${res.code}`),
								type: 'success',
								duration: 2.5
							});
							dispatch(setAuthModalVisible({visible: 'SIGN_IN'}));
						})
						.catch((err: any) => {
							const seconds =
								err.statusCode === 429 && err.code === 35 && err?.message?.match(/(\d+)/)[0];
							Toast({
								description:
									err.statusCode === 429 && err.code === 35
										? t(`messages.code.${err.code}`, {seconds})
										: t(`messages.code.${err.code}`),
								type: 'error',
								duration: 2.5
							});
						});
				}
				if (visible === 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL') {
					// WHEN SIGNING IN BY EMAIL ONLY
					dispatch(signInWithEmailOtpThunk({email: emailForOtp, otp: newValues.otp}))
						.unwrap()
						.then((res: any) => {
							localStorage.setItem('token', JSON.stringify(res.data.token).replace(/"/g, ''));
							Toast({
								description: t(`messages.code.${res.code}`),
								type: 'success',
								duration: 2.5
							});
							if (res.data.require === true) {
								dispatch(setAuthModalVisible({visible: 'SET_PASSWORD'}));
							} else {
								localStorage.setItem(
									'token',
									JSON.stringify(res.data.token).replace(/"/g, '')
								);
								dispatch(setAuthModalVisible({visible: false}));
								dispatch(getUserThunk());
							}
						})
						.catch((err: any) => {
							const seconds =
								err.statusCode === 429 && err.code === 35 && err?.message?.match(/(\d+)/)[0];
							Toast({
								description:
									err.statusCode === 429 && err.code === 35
										? t(`messages.code.${err.code}`, {seconds})
										: t(`messages.code.${err.code}`),
								type: 'error',
								duration: 2.5
							});
						});
				}
				if (visible === 'SET_PASSWORD') {
					let newValues = {
						...values,
						password: values['new-password'],
						token: localStorage.getItem('token')
					};
					delete newValues['new-password'];
					dispatch(setPasswordThunk(newValues))
						.unwrap()
						.then(({data}: any) => {
							Toast({
								description: data.code
									? t(`messages.code.${data.code}`)
									: t(`messages.code.1`),
								type: 'success',
								duration: 2.5
							});
							localStorage.setItem('token', JSON.stringify(data.data.token).replace(/"/g, ''));
							dispatch(setAuthModalVisible({visible: false}));
							dispatch(getUserThunk());
						})
						.catch((err: any) => {
							const seconds =
								err.statusCode === 429 && err.code === 35 && err?.message?.match(/(\d+)/)[0];

							Toast({
								description:
									err.statusCode === 429 && err.code === 35
										? t(`messages.code.${err.code}`, {seconds})
										: t(`messages.code.${err.code}`),
								type: 'error',
								duration: 2.5
							});
						});
				}
				if (visible === 'RESET_PASSWORD') {
					let newValues = {
						...values,
						password: values['new-password'],
						token: localStorage.getItem('token')
					};
					delete newValues['new-password'];
					dispatch(resetPasswordThunk(newValues))
						.unwrap()
						.then((res: any) => {
							Toast({
								description: res.code ? t(`messages.code.${res.code}`) : t(`messages.code.1`),
								type: 'success',
								duration: 2.5
							});
							localStorage.setItem(
								'token',
								JSON.stringify(res.data.data.token).replace(/"/g, '')
							);
							dispatch(setAuthModalVisible({visible: false}));
							dispatch(getUserThunk());
							// dispatch(setAuthModalVisible({visible: 'SIGN_IN'}));
						})
						.catch((err: any) => {
							const seconds =
								err.statusCode === 429 && err.code === 35 && err?.message?.match(/(\d+)/)[0];

							Toast({
								description:
									err.statusCode === 429 && err.code === 35
										? t(`messages.code.${err.code}`, {seconds})
										: t(`messages.code.${err.code}`),
								type: 'error',
								duration: 2.5
							});
						});
				}
			})
			.catch(err => {
				const error = err.errorFields?.[0]?.name?.[0];
				if (error) {
					const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
					if (errorField) {
						errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
					}
				}
			});
	};

	useEffect(() => {
		if (visible === false) {
			form.resetFields();
			setIsSuccess(false);
		}
	}, [visible, form]);

	useEffect(() => {
		if (formInitialValues) {
			form.setFieldsValue(formInitialValues);
		}
	}, [formInitialValues, form]);

	useEffect(() => {
		if (
			visible === 'OTP_CONFIRMATION' ||
			visible === 'OTP_VERIFICATION' ||
			visible === 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL'
		) {
			setDeadline(() => Date.now() + 1000 * defaultDeadline);
		}
	}, [visible]);

	const resendOtp = () => {
		dispatch(resendOtpThunk({email: emailForOtp}))
			.unwrap()
			.then((res: any) => {
				setDeadline(() => Date.now() + 1000 * defaultDeadline);
				Toast({
					description: t(`messages.code.${res.data.code}`),
					type: 'success',
					duration: 2.5
				});
			})
			.catch((err: any) => {
				const seconds = typeof err.message === 'string' && err?.message?.match(/(\d+)/)[0];
				setDeadline(() => Date.now() + 1000 * +seconds);
				Toast({
					description: t(`messages.code.${err.code}`, {seconds}),
					type: 'error',
					duration: 2.5
				});
			})
			.finally(() => {
				setIsDisabled(true);
			});
	};

	return visible ? (
		<Modal
			open={!!visible}
			className={`auth-modal ${visible}`}
			title={auth.modal.title ? auth.modal.title : getTitle(visible, t)}
			onCancel={auth.loading ? undefined : handleModalClose}
			maskClosable={true}
			style={{top: isMobile ? 0 : 120}}
			width={dialogSize}
			footer={[]}>
			<Form
				form={form}
				layout="vertical"
				validateMessages={validateMessages(t)}
				initialValues={formInitialValues}
				validateTrigger="onBlur"
				onValuesChange={onValuesChange}
				onFinish={onSubmit}>
				{!!visible && (
					<FormGroup
						tabFields={getAuthFields({
							visible,
							t,
							dispatch,
							isSuccess,
							isLoading: auth.loading,
							otpEmail: emailForOtp
						})}
						formErrors={auth.formErrors}
						form={form}
					/>
				)}
				<div style={{display: 'flex', justifyContent: 'center'}}>
					{visible === 'SIGN_UP' && (
						<ReCAPTCHA sitekey="6LcRSIQpAAAAAE9WEsFMgM3HBkSTJozTavzzl8yW" onChange={onChange} />
					)}
					{
						/* visible === 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL' || */ visible ===
							'OTP_CONFIRMATION' /* ||
						visible === 'OTP_VERIFICATION' */ && (
							<div className="">
								<div className="">
									{/* <p
										className="resend-code__button"
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: isDisabled ? 10 : 0
										}}
										size="large"
										onClick={resendOtp}
										disabled={isDisabled}>
										<span style={{textDecoration: 'underline', color: '#009e83'}}>
											{t('actions.resendCode')}
										</span>
										<Statistic.Countdown
											className={`resend-code__countDown ${
												isDisabled ? 'visible' : 'invisible'
											}`}
											format="MM:ss"
											value={deadline}
											onFinish={() => setIsDisabled(false)}
										/>
									</p> */}
									<Button
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: isDisabled ? 10 : 0
										}}
										size="large"
										onClick={resendOtp}
										disabled={isDisabled}>
										{t('actions.resendCode')}
										<Statistic.Countdown
											className={`resend-code__countDown ${
												isDisabled ? 'visible' : 'invisible'
											}`}
											format="MM:ss"
											value={deadline}
											onFinish={() => setIsDisabled(false)}
										/>
									</Button>
								</div>
							</div>
						)
					}
				</div>
				<div className="ant-modal-footer">
					<>
						{visible !== 'SIGN_IN' && !isSuccess && (
							<div key="cancel">
								<Button
									className="main-btn"
									size="large"
									onClick={handleModalClose}
									style={{marginRight: 15}}>
									{cancelText || t('actions.cancel')}
								</Button>
							</div>
						)}

						<div key="submit">
							<Button
								className="main-btn"
								size="large"
								type="primary"
								htmlType="submit"
								onSubmit={form.submit}
								disabled={auth.loading}
								loading={auth.loading}>
								{getOkText(visible, t)}
							</Button>
						</div>
					</>
				</div>
			</Form>
		</Modal>
	) : (
		<></>
	);
};

export default AuthModalForm;
