import {Empty} from 'antd';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import DataExport from '../../../../../Components/DataExport/DataExport';
import PagePagination from '../../../../../Components/Pagination/PagePagination';
import {AppDispatch} from '../../../../../store';
import i18n from '../../../../../utils/translate/i18';
import {usePagination} from '../../../../../utils/usePagination/usePagination';
import DefaultWrapper from '../../DefaultWrapper/DefaultWrapper';
import {getPartnerTransactions} from './../../../../../store/partner.transaction.slice';
import './styles.less';
import TransactionData from './TransactionData';
import transactionForm from './transacxtionForm';

const Transaction = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const {pathname} = useLocation();
	const currentPageName = '/partner/transactions';
	const transactions = useSelector(({transactions}) => transactions.data);
	const [pageSize, setPageSize] = useState(4);
	const auth = useSelector((state: any) => state.auth.data);
	const [info, setInfo] = useState<any>(null);
	const {currentItemsData, currentPage, goToPage} = usePagination(
		transactions.items,
		transactions.pageSize
	);

	useEffect(() => {
		let newValues = {...info};
		if (newValues.cityId) {
			delete newValues.cityId;
		}
		if (info?.routeNumber) {
			newValues.routeNumber = info.routeNumber.split('_')[1];
		}
		if (currentPageName === pathname) {
			dispatch(
				getPartnerTransactions({
					page: currentPage,
					filter: newValues,
					take: pageSize
				})
			);
		}
	}, [currentPage, info, pathname, pageSize]);

	const columns = [
		{dataIndex: 'status', title: t('pages.account.partner.transactions.transferstatus')},
		{dataIndex: 'type', title: t('pages.account.partner.transactions.transactionType')},
		{dataIndex: 'holderType', title: t('pages.tickets.oneUse.ticketCard.storage')},
		{dataIndex: 'amount', title: t('pages.account.paymentHistoryPage.ticketItem.ticketAbout.cost')},
		{dataIndex: 'transactionDate', title: t('pages.account.partner.transactions.timePeriod')},
		{dataIndex: 'routeNumber', title: t('pages.account.partner.transactions.routes')},
		{dataIndex: 'validatorSerialNumber', title: t('pages.account.partner.transactions.terminal')},
		{dataIndex: 'transportYellowLicensePlate', title: t('pages.account.partner.buses.titles')}
	];

	const rows = currentItemsData?.map((transaction: any) => {
		return {
			status: transaction?.status ? transaction?.status[i18n.language] : ' ',
			type: transaction?.type ? transaction?.type[i18n.language] : ' ',
			holderType: transaction?.holderType ? transaction?.holderType[i18n.language] : ' ',
			amount: transaction?.amount,
			transactionDate: dayjs(transaction?.transactionDate).format('DD-MM-YYYY HH-mm').toString(),
			routeNumber: transaction?.routeNumber,
			validatorSerialNumber: transaction?.validatorSerialNumber,
			transportYellowLicensePlate: transaction?.transportYellowLicensePlate
		};
	});

	return (
		<div className="transactiondata-all-div">
			<div className="partner-routes__container">
				<div className="partner-routes__content">
					<div className="partner-routes__top">
						<DataExport exportData={{rows, columns}} />
						<DefaultWrapper
							filteritems={transactionForm}
							defaultFilters={info}
							applyFilters={(data: any) => {
								goToPage(1);

								setInfo(data);
							}}
							customFilters={{cityId: auth.cityId}}
						/>
					</div>
					{currentItemsData && currentItemsData?.length > 0 ? (
						<>
							<div className="transaction-data__row">
								<div className="transaction-data">
									<div className="transaction-data-container">
										<div className="transaction-data-content">
											<div className="transaction-data-row">
												{currentItemsData?.map((elem: any) => (
													<TransactionData key={elem.id} options={elem} />
												))}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div style={{marginTop: 20}}>
								<PagePagination
									pageSizeOptions={[4, 10, 20, 50, 100]}
									pageSize={pageSize}
									currentPage={transactions.currentPage}
									totalItems={transactions.totalCount}
									onPageChange={goToPage}
									onTakeChange={size => {
										setPageSize(size);
										goToPage(1); // Reset to the first page when page size changes
									}}
								/>
							</div>
						</>
					) : (
						<div style={{padding: '50px 0px'}}>
							<Empty />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Transaction;
